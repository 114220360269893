import React from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderB,
    PageSection,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { graphql } from 'gatsby';
import { ServiceJumpTable } from '../components/servicejumptable';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { makeSectionId } from '../components/jumpboxlink';
import Seo from '../components/seo';

function InformationSecurity({ data, location }) {
    const { t } = useTranslation();
    const text = [
        {
            title: t('informationssicherheit_title', 'Informationssicherheit'),
            desc: t(
                'informationssicherheit_desc',
                'Der Weg zu einem nachhaltigen Security Management'
            ),
        },
        {
            title: t('risiko_management_title', 'Risiko Management'),
            desc: t(
                'risiko_management_desc',
                'Risikomanagement – so bleibst du auf alles vorbereitet!'
            ),
        },
        {
            title: t('kritis_title', 'Umsetzungsunterstützung NIS2 / KRITIS'),
            desc: t(
                'kritis_desc',
                'Cybersicherheit für Betreiber kritischer Infrastrukturen'
            ),
        },
        {
            title: t('nis_readiness_title', 'NIS2 Readiness Check'),
            desc: t(
                'nis_readiness_desc',
                'Vorbereitung zur NIS2 Compliance'
            ),
        },
        {
            title: t('nis_compliance_title', 'NIS Prüfung durch Qualifizierte Stelle'),
            desc: t(
                'nis_compliance_desc',
                'Nachweis der NIS Compliance'
            ),
        },
        {
            title: t('cyber_trust_title', 'Cyber Trust Label Austria'),
            desc: t(
                'cyber_trust_desc',
                'Das Österreichische Gütesiegel für Cybersicherheit'
            ),
        },
        {
            title: t('ciso_as_a_service_title', 'CISO as a Service'),
            desc: t(
                'ciso_as_a_service_desc',
                'Expertise und eine helfende Hand für Cybersicherheit'
            ),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.services}
            headerContent={
                <PageHeaderB
                    title={t('title', 'Information Security')}
                    text={
                        <Trans i18nKey="subtitle">
                            Geschäfts- und Betriebsgeheimnisse zu schützen,
                            stellt eine der wichtigsten Aufgaben eines
                            Unternehmens dar. Informationssicherheit definiert
                            dafür technische und organisatorische Maßnahmen.
                        </Trans>
                    }
                />
            }
        >
            <Seo title={t('title', 'Information Security')} />

            <ServiceJumpTable items={text} />

            <PageSection size="big" id={makeSectionId(text[0].title)}>
                <PageTitle>{text[0].title}</PageTitle>
                <PageSubtitle>{text[0].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="informationssicherheit_p1">
                        Daten werden häufig als das Gold des digitalen
                        Zeitalters bezeichnet. Gerade aufgrund dieses
                        Stellenwertes ist es wichtig verlässliche Maßnahmen zu
                        setzen, um die wesentlichen Schutzziele der
                        Informationssicherheit - Vertraulichkeit, Integrität und
                        Verfügbarkeit sicherzustellen. Die ISO/IEC 27001 stellt
                        dabei eine der anerkanntesten Normen im Bereich der
                        Informationssicherheit und definiert Rahmenbedingungen
                        für eine ganzheitliche Sicherheitsbetrachtung im Rahmen
                        eines ISMS (Informationssicherheitsmanagement-Systems).
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="informationssicherheit_p2">
                        Als condignum unterstützten wir Sie sowohl beim Aufbau
                        zertifizierungsfähiger Information Security
                        Managementsysteme (ISMS), als auch bei der Überprüfung
                        und Optimierung bestehender Systeme nach ISO/IEC 27001.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[1].title)} isDark>
                <PageTitle>{text[1].title}</PageTitle>
                <PageSubtitle>{text[1].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="risiko_management_p1">
                        Zahl und Komplexität von Cyberangriffen nehmen laufend
                        zu und die Umsetzung angemessener Sicherheitsmaßnahmen
                        wird damit ein zunehmend schwierigeres Unterfangen.Nur
                        wer sein Risikoprofil kennt ist dazu in der Lage, die
                        richtigen Prioritäten zu setzen, Risiken effektiv zu
                        behandeln und vorhandene Ressourcen effizient
                        einzusetzen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="risiko_management_p2">
                        Als condignum unterstützen wir Sie auf Basis anerkannter
                        Standards wie ISO/IEC 27005, ISO/IEC 31000 und BSI 200-3
                        über den gesamten Risikomanagementprozess - von der
                        Identifikation von Bedrohungen über die Bewertung von
                        Risiken, bis zur Erarbeitung von Sicherheitsmaßnahmen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="risiko_management_p3">
                        Darauf aufbauend können wir bei der Entwicklung von
                        Sicherheitskonzepten und technischen
                        Sicherheitsarchitekturen unterstützen, welche auf ihr
                        Unternehmen und ihre Anwendungsfälle zugeschnitten sind.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[2].title)}>
                <PageTitle>{text[2].title}</PageTitle>
                <PageSubtitle>{text[2].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="kritis_p1">
                    Mit der EU NIS2-Direktive wurden im europäischen Raum Mindeststandards zum Schutz kritischer Infrastrukturen geschaffen. Mit der Umsetzung des NIS-Gesetztes in Österreich, bez. des IT-Sicherheitsgesetztes (IT-SiG) in Deutschland wurden Betreiber wesentlicher kritischer Dienste zur Umsetzung von technischen und organisatorischen Sicherheitsmaßnahmen verpflichtet. Getroffene Maßnahmen müssen dabei regelmäßig verifiziert und nachgewiesen werden.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="kritis_p2">
                    Als akkreditierte qualifizierte Stelle (QuaSte), unterstützt Sie condignum bei der Umsetzung und regelmäßigen Überprüfung der angewandten Sicherheitsmaßnahmen auf Wirksamkeit, Angemessenheit und Effektivität.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[3].title)} isDark>
                <PageTitle>{text[3].title}</PageTitle>
                <PageSubtitle>{text[3].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="nis_readiness_p1">
                    Mit der NIS2-Richtlinie hat die EU die Grundlage zur Steigerung der Cybersicherheit im europäischen Raum gelegt. Im Vergleich zur ersten Version wurden unter anderem die betroffenen Sektoren massiv erweitert und Regelungen zur Lieferkettensicherheit ergänzt. Dies führt dazu, dass nicht nur die Anzahl der direkt betroffenen Organisationen enorm steigt, sondern viele Organisationen auch indirekt als Zulieferer für NIS2-relevante Organisationen ihre Sicherheitsstandards heben müssen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis_readiness_p2">
                    Sie sind direkt oder indirekt von NIS2 betroffen? Wissen jedoch aufgrund der bisher ausständigen nationalen Gesetzgebung in Österreich nicht so wirklich wo sie anfangen sollen?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis_readiness_p3">
                    Unser NIS2 Readiness Check hilft Ihnen herauszufinden wo Sie aktuell stehen und wo Ihre größten Herausforderungen zur Erreichung der NIS2 Compliance liegen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis_readiness_p4">
                    Als akkreditierte Qualifizierte Stelle helfen wir Ihnen die aktuelle Informationslage bestmöglich zu interpretieren und die richtigen Entscheidungen für Ihre Vorbereitungen zur NIS2 zu treffen.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[4].title)}>
                <PageTitle>{text[4].title}</PageTitle>
                <PageSubtitle>{text[4].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="nis_compliance_p1">
                    Sie sind Betreiber eines wesentlichen Dienstes und benötigen eine Überprüfung durch eine Qualifizierte Stelle um die korrekte Umsetzung der NIS Anforderungen nachzuweisen?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis_compliance_p2">
                    Als akkreditierte Qualifizierte Stelle mit mehreren akkreditierten NIS Prüfern unterstütz condignum Sie gerne bei der Überprüfung Ihrer Konformität zum NIS Gesetz.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[5].title)} isDark>
                <PageTitle>{text[5].title}</PageTitle>
                <PageSubtitle>{text[5].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="cyber_trust_p1">
                    Durch die Erlangung des Cyber Trust Austria Label - dem österreichischen Gütesiegel für Cybersicherheit - können Sie zeigen, dass Security Ihnen wichtig ist und Ihre Kunden und Partner Ihnen vertrauen können.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="cyber_trust_p2">
                    Als akkreditierte Qualifizierten Stelle und Cyber Trust Austria Partner begleiten wir Sie auf dem Weg zur Erlangung dieses Gütesiegels.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[6].title)}>
                <PageTitle>{text[6].title}</PageTitle>
                <PageSubtitle>{text[6].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="ciso_as_a_service_p1">
                        In jedem Unternehmen sollte es eine Person geben, die
                        den aktuellen Stand der Informationssicherheit
                        überblickt und der Geschäftsführung die entsprechenden
                        Informationen bereitstellt, um informierte und bewusste
                        Entscheidungen treffen zu können. Doch insbesondere für
                        KMUs ist es nicht immer möglich eine dedizierte Person
                        dafür zu engagieren.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="ciso_as_a_service_p2">
                        Durch das condignum “CISO as a Service” Modell bekommen
                        Sie einen erfahrenen Security Experten an Ihre Seite -
                        immer genau dann, wenn Sie ihn benötigen. Unsere Berater
                        kennen vergleichbare Unternehmen und Ihre
                        Herausforderungen und stehen Ihnen bei der Planung,
                        Umsetzung und Nachhaltung von Security-Aktivitäten stets
                        tatkräftig zur Seite.
                    </Trans>
                </PageText>
            </PageSection>

            <ContactSection requestDemo={false} isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["information-security"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default InformationSecurity;
